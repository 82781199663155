import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import { FlatButton } from '../../../button';

import { getRefundText } from '../../../../bi/utils/trip';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/index.module.css';

const LABELS = {
  CANCEL_BUTTON: 'Отмена',
  REFUND: 'Сумма к возврату',
  CANCELLATION: 'Сумма к списанию',
  TAXI: {
    APROOVE_TITLE: 'Внимание! Это финальный шаг, ваучер будет отменен у поставщика и в поездке.',
    CONFIRM_BUTTON: 'Отменить ваучер',
  },
  VIP_HALL: {
    REFUND_PRICE: price => `Сумма штрафа ${price} р.`,
    APROOVE_TITLE: 'Внимание! Это финальный шаг, бронь будет отменена у поставщика и в поездке.',
    CONFIRM_BUTTON: 'Отменить бронирование',
  },
};

const DIALOGS_WIDTH = '400px';

const AprooveDialog = ({
  isAprooveDialog,
  cancelVoucherInputValue,
  setAprooveDialog,
  setCancelVoucherInputValue,
  serviceType,
  amountCancelVoucher,
  cancelVoucher,
}) => {
  if (!isAprooveDialog) return null;

  const isVipHall = serviceType === SERVICETYPE.VIP_HALL;

  const handleCloseAprooveDialog = () => {
    setAprooveDialog(false);
    setCancelVoucherInputValue('');
  };

  const handleAprooveButton = () => cancelVoucher(cancelVoucherInputValue, serviceType);

  const title = isVipHall
    ? LABELS.VIP_HALL.APROOVE_TITLE
    : LABELS.TAXI.APROOVE_TITLE;

  const price = isVipHall
    ? LABELS.VIP_HALL.REFUND_PRICE(amountCancelVoucher)
    : getRefundText(amountCancelVoucher, LABELS.REFUND, LABELS.CANCELLATION);

  const confirm = isVipHall
    ? LABELS.VIP_HALL.CONFIRM_BUTTON
    : LABELS.TAXI.CONFIRM_BUTTON;

  return (
    <Dialog
      width={ DIALOGS_WIDTH }
      onClick={ handleCloseAprooveDialog }
    >
      <div className={ styles.dialog }>
        <h3>
          { title }
        </h3>
        <div className={ styles.refund_text }>
          { price }
        </div>
        <div className={ styles.buttons_row }>
          <Button
            theme='red'
            disabled={ !cancelVoucherInputValue }
            onClick={ handleAprooveButton }
          >
            { confirm }
          </Button>
          <div>
            <FlatButton
              onClick={ handleCloseAprooveDialog }
            >
              { LABELS.CANCEL_BUTTON }
            </FlatButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AprooveDialog.propTypes = {
  isAprooveDialog: PropTypes.bool.isRequired,
  setAprooveDialog: PropTypes.func.isRequired,
  setCancelVoucherInputValue: PropTypes.func.isRequired,
  cancelVoucher: PropTypes.func.isRequired,
  cancelVoucherInputValue: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  amountCancelVoucher: PropTypes.number.isRequired,
};


export { AprooveDialog };

