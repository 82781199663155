import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/flatButton.module.css';
import CircularLoaders from '../loaders';
import classNames from '../../utils/classNames';

class FlatButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    withWrap: PropTypes.bool,
    inValidMessage: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    label: '',
    type: 'button',
    children: null,
    loading: false,
    disabled: false,
    withWrap: false,
    inValidMessage: '',
    onClick: () => {},
  };

  onClick = (e) => {
    e.preventDefault();

    this.props.onClick(e);
  };

  renderLoadingComponent() {
    return (
      <CircularLoaders wrapWidth={ 36 } />
    );
  }

  render() {
    const { type, label, children, loading, disabled, inValidMessage, withWrap } = this.props;

    let html = null;

    if (loading) {
      html = this.renderLoadingComponent();
    } else if (!label && !loading) {
      html = children;
    } else {
      html = label;
    }

    const message = inValidMessage && <div className={ styles.error_message }>{inValidMessage}</div>;

    const buttonClasses = classNames({
      [styles.button]: true,
      [styles.flat]: true,
      [styles.with_wrap]: withWrap,
    });

    return (
      <div>
        <button
          className={ buttonClasses }
          type={ type }
          onClick={ this.onClick }
          disabled={ disabled }
        >
          { html }
        </button>
        {message}
      </div>
    );
  }
}

export default FlatButton;
