import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import COMPONENTS from '../../bi/constants/components';

import { TYPE_ICON } from './consts';

import styles from './index.module.css';

const AddButton = ({ isNoEdit, icon, text, onClick }) => {
  const buttonStyles = [styles.button];

  if (icon === TYPE_ICON.CLEAR) {
    buttonStyles.push(styles.button_close);
  }

  return (
    <div className={ `sw-tooltip-wrapper ${styles.wrapper}` }>
      <button className={ buttonStyles.join(' ') } onClick={ onClick } disabled={ isNoEdit }>
        <i className='material-icons'>{icon}</i>
      </button>
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
      >
        { text }
      </Tooltip>
    </div>
  );
};

AddButton.propTypes = {
  isNoEdit: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

AddButton.defaultProps = {
  isNoEdit: false,
  icon: TYPE_ICON.ADD,
};

export { AddButton };
