import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/button.module.css';

class Button extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: 'button',
    disabled: false,
    onClick: () => {},
    className: '',
  };

  onClick = (e) => {
    e.preventDefault();

    this.props.onClick(e);
  };

  render() {
    const { type, label, className, disabled } = this.props;

    return (
      <button
        className={ `${styles.button} ${className}` }
        type={ type }
        onClick={ type === 'submit' ? null : this.onClick }
        disabled={ disabled }
      >
        { label }
      </button>
    );
  }
}

export default Button;
