import { findDepartments } from '../utils/trip';
import {
  isMoment, dateLessThanOtherDate, momentObject, formatDataValue,
} from '../utils/formatDate';
import {
  applyAnalytics, removeAnalytics, validateSingleAnalytics,
} from '../utils/customAnalytics';

import { FIELDS, FLIGHT_TYPE_FORMAT } from '../constants/trips';
import {
  DEFAULTTIME, DATE, DEFAULTDATE,
} from '../../constants/time';

const editTrip = (jsonData) => {
  const {
    CancellationDate,
    CheckinDate,
    CheckoutDate,
    Flight,
  } = jsonData;
  const cancellationTime = momentObject(CancellationDate, DEFAULTTIME);
  const checkinTime = momentObject(CheckinDate, DEFAULTTIME);
  const checkoutTime = momentObject(CheckoutDate, DEFAULTTIME);

  const prepareFlightEdit = Flight.map((item) => ({
    ...item,
    Terminal: item.DepartureAirport.Terminal || item.ArrivalAirport.Terminal,
    DepartureAirport: {
      choosenSuggest: {
        Code: item.DepartureAirport.Code,
        City: item.DepartureAirport.City,
        Name: item.DepartureAirport.Name,
      },
      label: `${item.DepartureAirport.Code} ${item.DepartureAirport.City} ${item.DepartureAirport.Name}`,
      selected: {},
      suggests: [],
    },
    ArrivalAirport: {
      choosenSuggest: {
        Code: item.ArrivalAirport.Code,
        City: item.ArrivalAirport.City,
        Name: item.ArrivalAirport.Name,
      },
      label: `${item.ArrivalAirport.Code} ${item.ArrivalAirport.City} ${item.ArrivalAirport.Name}`,
      selected: {},
      suggests: [],
    },
    CheckinDate: momentObject(item.DepartureDate, DEFAULTDATE),
    CheckoutDate: momentObject(item.ArrivalDate, DEFAULTDATE),
    CheckinTime: momentObject(item.DepartureDate, DEFAULTTIME),
    CheckoutTime: momentObject(item.ArrivalDate, DEFAULTTIME),
  }));

  return {
    cancellationTime,
    checkinTime,
    checkoutTime,
    flight: prepareFlightEdit,
  };
};

const defaultStateTrip = (jsonData, defaultJsonData) => {
  const {
    OrderTripItemId, ServiceType, PriceDetails,
  } = defaultJsonData;

  return {
    OrderTripItemId,
    ServiceType,
    PriceDetails,
    JsonData: {
      ...jsonData,
      CancellationDate: momentObject(jsonData.CancellationDate),
      CheckinDate: momentObject(jsonData.CheckinDate),
      CheckoutDate: momentObject(jsonData.CheckoutDate),
    },

    validation: {
      Name: '',
      ProviderOrderId: '',
      CheckinDate: '',
      CheckinTime: '',
      CheckoutDate: '',
      CheckoutTime: '',
      CancellationDate: '',
      CancellationTime: '',
      Employees: '',
      CompanyId: '',
      ProjectId: '',
      Providers: '',
      DepartmentId: '',
      AirportFrom: '',
      AirportTo: '',
      Base: '',
      commission: '',
      ProviderName: '',
      Terminal: '',
      Segments: jsonData.Flight.map(() => ({
        DepartureAirport: '',
        ArrivalAirport: '',
        Terminal: '',
        Number: '',
        CheckinDate: '',
        CheckoutDate: '',
        CheckinTime: '',
        CheckoutTime: '',
      })),
      analytics: {},
    },
  };
};

const createStateTrip = (jsonData, defaultJsonData) => {
  const { Employees, UserAnalytics } = defaultJsonData;

  return {
    ...defaultStateTrip(jsonData, defaultJsonData),
    Status: 0,
    Employees,
    CompanyId: 0,
    EmployeeId: 0,
    Terminal: '',
    JsonData: {
      ...jsonData,
      Flight: [
        {
          ...jsonData.Flight[0],
          DepartureAirport: {
            label: '',
            selected: {},
            suggests: [],
          },
          ArrivalAirport: {
            label: '',
            selected: {},
            suggests: [],
          },
          Terminal: '',
          Number: '',
          CheckinDate: '',
          CheckoutDate: '',
          CheckinTime: '',
          CheckoutTime: '',
        },
      ],
    },
    CheckinTime: momentObject(jsonData.CheckinDate),
    CheckoutTime: momentObject(jsonData.CheckoutDate),
    ProjectId: 0,
    Providers: '',
    DepartmentId: 0,
    Departments: [],
    UserAnalytics: UserAnalytics || [],
    mandatoryProject: false,
    employeesListForPick: [{ id: 0 }],
    selectedEmployeesIds: [],

    employeesList: [],
    providersList: [],
  };
};

const editStateTrip = (jsonData, defaultJsonData) => {
  const { JsonData } = defaultJsonData;

  const cancellationTime = momentObject(JsonData.CancellationTime, DEFAULTTIME);
  const checkinTime = momentObject(JsonData.CheckinTime, DEFAULTTIME);
  const checkoutTime = momentObject(JsonData.CheckoutTime, DEFAULTTIME);

  return {
    ...defaultJsonData,
    ...defaultStateTrip(JsonData, defaultJsonData),
    JsonData: {
      ...defaultStateTrip(JsonData, defaultJsonData).JsonData,
      Flight: JsonData.Flight.map((item) => ({
        ...item,
        CheckinDate: momentObject(item.CheckinDate, DEFAULTDATE),
        CheckoutDate: momentObject(item.CheckoutDate, DEFAULTDATE),
        CheckinTime: momentObject(item.CheckinTime, DEFAULTTIME),
        CheckoutTime: momentObject(item.CheckoutTime, DEFAULTTIME),
      })),
      CheckinTime: checkinTime,
      CheckoutTime: checkoutTime,
      CancellationTime: cancellationTime,
    },
  };
};

const editingStateTrip = (jsonData, defaultJsonData) => {
  const {
    Employees,
    UserAnalytics,
    ProjectId,
    DepartmentId,
    CompanyId,
    ProviderName,
  } = defaultJsonData;

  const {
    PriceDetails: PriceDetailsObj,
    AdditionalInformation,
  } = jsonData;
  const selectedEmployeesIds = Employees.map(({ Id }) => Id);
  const employeesListForPick = Employees.map((emp, index) => ({ ...emp, id: index }));

  const {
    cancellationTime, checkinTime, checkoutTime, flight,
  } = editTrip(jsonData);

  return {
    ...defaultStateTrip(jsonData, defaultJsonData),
    Status: 1,
    Description: '',
    ProjectId,
    DepartmentId: DepartmentId || 0,
    CompanyId: CompanyId || 0,
    Departments: [],
    JsonData: {
      ...defaultStateTrip(jsonData, defaultJsonData).JsonData,
      AdditionalInfo: AdditionalInformation.join('\n'),
      CheckinTime: checkinTime,
      CheckoutTime: checkoutTime,
      CancellationTime: cancellationTime,
      Flight: flight,
    },
    PriceDetails: {
      Base: PriceDetailsObj.Base,
      BasePrice: PriceDetailsObj.BasePrice,
      Fee: PriceDetailsObj.Fee,
      Tax: PriceDetailsObj.Tax,
      Commission: PriceDetailsObj.Commission,
    },
    Providers: ProviderName || '',
    UserAnalytics: UserAnalytics || [],
    mandatoryProject: false,
    employeesListForPick,
    selectedEmployeesIds,

    employeesList: [],
    providersList: [],
  };
};

const copyStateTrip = (jsonData, defaultJsonData) => {
  const { UserAnalytics } = defaultJsonData;
  const {
    cancellationTime, checkinTime, checkoutTime, flight,
  } = editTrip(jsonData);

  return {
    ...defaultStateTrip(jsonData, defaultJsonData),
    Status: 0,
    CompanyId: 0,
    ProjectId: 0,
    DepartmentId: 0,
    Departments: [],
    JsonData: {
      ...defaultStateTrip(jsonData, defaultJsonData).JsonData,
      CheckinTime: checkinTime,
      CheckoutTime: checkoutTime,
      CancellationTime: cancellationTime,
      Flight: flight,
    },
    Providers: '',
    UserAnalytics: UserAnalytics || [],
    mandatoryProject: false,
    employeesListForPick: [{ id: 0 }],
    selectedEmployeesIds: [],

    employeesList: [],
    providersList: [],
  };
};

const normalizeDateConfirm = (jsonData) => {
  const {
    Flight, CancellationDate, CancellationTime,
  } = jsonData;

  const checkinDate = Flight.length > 1 ? Flight[0].ArrivalDate : Flight[0].DepartureDate;
  const checkoutDate = Flight.length > 1 ? Flight[1].DepartureDate : Flight[0].ArrivalDate;

  let cancellationDate;
  let cancellationTime;

  if (typeof CancellationDate !== 'string') cancellationDate = formatDataValue(CancellationDate, DATE);
  if (typeof CancellationTime !== 'string') cancellationTime = formatDataValue(CancellationTime, DEFAULTTIME);

  cancellationDate = `${cancellationDate}T${cancellationTime}`;
  cancellationTime = 0;

  return {
    checkinDate, checkoutDate, cancellationDate, cancellationTime,
  };
};

const normalizeFlightConfirm = (jsonData) => {
  const { Flight } = jsonData;

  const flight = Flight.map((segment, index) => {
    const {
      DepartureAirport, Type, ArrivalAirport, Terminal, CheckinDate, CheckinTime, CheckoutDate, CheckoutTime,
    } = segment;

    let checkinDate;
    let checkoutDate;
    let checkinTime;
    let checkoutTime;

    const isDeparture = Type === FLIGHT_TYPE_FORMAT.DEPARTURE;

    if (typeof CheckinDate !== 'string') checkinDate = formatDataValue(CheckinDate, DATE);
    if (typeof CheckinTime !== 'string') checkinTime = formatDataValue(CheckinTime, DEFAULTTIME);
    if (typeof CheckoutDate !== 'string') checkoutDate = formatDataValue(CheckoutDate, DATE);
    if (typeof CheckoutTime !== 'string') checkoutTime = formatDataValue(CheckoutTime, DEFAULTTIME);

    return {
      ...segment,
      DepartureDate: `${checkinDate}T${checkinTime}`,
      ArrivalDate: `${checkoutDate}T${checkoutTime}`,
      DepartureAirport: {
        ...DepartureAirport.choosenSuggest,
        Terminal: isDeparture ? Terminal : '',
      },
      ArrivalAirport: {
        ...ArrivalAirport.choosenSuggest,
        Terminal: isDeparture ? '' : Terminal,
      },
      IsTransit: index === 1,
      Date: isDeparture ? CheckinDate : CheckoutDate,
    };
  });

  return flight;
};

const removeEmployeeSuggest = (index, employeesList, selectedIds, companyId, isEditing, departments) => {
  const newEmployeesList = employeesList.map((item, itemIndex) => {
    if (itemIndex === index) {
      return { id: item.id };
    }

    return item;
  });

  const currentEmployeeId = employeesList.find((el) => el.id === index).Id;
  const updSelectedEmployeesIds = selectedIds.filter((empId) => empId !== currentEmployeeId);
  const noEmployees = newEmployeesList.every(({ Id }) => !Id);
  const newDepartment = isEditing ? departments : findDepartments(newEmployeesList[0], companyId);

  return {
    newEmployeesList,
    updSelectedEmployeesIds,
    noEmployees,
    newDepartment,
  };
};

const validateDateFields = (validation, jsonData, value, field) => {
  const { CheckoutDate, CheckinDate } = jsonData;

  let result;
  let resultCheckin;
  let resultCheckout;
  let resultCheckoutLess;

  if (CheckoutDate && CheckinDate && dateLessThanOtherDate(CheckoutDate, CheckinDate) < 0
    && (field === FIELDS.CHECKOUT_DATE || field === FIELDS.CHECKOUTTIME)) {
    resultCheckout = validation(FIELDS.CHECKOUTDATELESS, jsonData);
    resultCheckoutLess = validation(FIELDS.CHECKOUTDATELESS, jsonData);
  } else if ((field === FIELDS.CHECKIN_DATE
    || field === FIELDS.CHECKOUT_DATE
    || field === FIELDS.CHECKINTIME
    || field === FIELDS.CHECKOUTTIME)
  && (isMoment(CheckinDate) || isMoment(CheckoutDate))
  && (momentObject(CheckinDate).isValid() || momentObject(CheckoutDate).isValid())) {
    resultCheckin = validation(FIELDS.CHECKIN_DATE, jsonData);
    resultCheckout = validation(FIELDS.CHECKOUT_DATE, jsonData);
    resultCheckoutLess = validation(FIELDS.CHECKOUTDATELESS, jsonData);
  } else {
    result = validation(field, value);
  }

  return {
    result, resultCheckin, resultCheckout, resultCheckoutLess,
  };
};

const validateSubmitFields = (jsonData, validation) => {
  const {
    ProviderOrderId,
    Name,
    Flight,
    CancellationDate,
    CancellationTime,
  } = jsonData;

  const name = validation(FIELDS.NAME, Name);
  const providerOrderId = validation(FIELDS.PROVIDER_ORDER_ID, ProviderOrderId);

  const segmentsDepartureAirport = Flight
    && Flight.map(({ DepartureAirport }) => validation(FIELDS.DEPARTURE_AIRPORT, DepartureAirport.label));
  const segmentsArrivalAirport = Flight
    && Flight.map(({ ArrivalAirport }) => validation(FIELDS.ARRIVAL_AIRPORT, ArrivalAirport.label));
  const segmentsCheckinDate = Flight && Flight.map(({ CheckinDate }) => validation(FIELDS.CHECKIN_DATE, CheckinDate));
  const segmentsCheckinTime = Flight && Flight.map(({ CheckinTime }) => validation(FIELDS.CHECKINTIME, CheckinTime));
  const segmentsCheckoutDate = Flight
    && Flight.map(({ CheckoutDate }) => validation(FIELDS.CHECKOUT_DATE, CheckoutDate));
  const segmentsCheckoutTime = Flight
    && Flight.map(({ CheckoutTime }) => validation(FIELDS.CHECKOUTTIME, CheckoutTime));
  const segmentsNumber = Flight && Flight.map(({ Number }) => validation(FIELDS.NUMBER, Number));
  const segmentsTerminal = Flight && Flight.map(({ Terminal }) => validation(FIELDS.NUMBER, Terminal));

  const departureAirport = segmentsDepartureAirport.filter((item) => item !== '').length !== 0;
  const arrivalAirport = segmentsArrivalAirport.filter((item) => item !== '').length !== 0;
  const checkinDate = segmentsCheckinDate.filter((item) => item !== '').length !== 0;
  const checkinTime = segmentsCheckinTime.filter((item) => item !== '').length !== 0;
  const checkoutDate = segmentsCheckoutDate.filter((item) => item !== '').length !== 0;
  const checkoutTime = segmentsCheckoutTime.filter((item) => item !== '').length !== 0;
  const number = segmentsNumber.filter((item) => item !== '').length !== 0;
  const terminal = segmentsTerminal.filter((item) => item !== '').length !== 0;

  const segments = Flight.map((_, index) => ({
    DepartureAirport: segmentsDepartureAirport[index],
    ArrivalAirport: segmentsArrivalAirport[index],
    Terminal: segmentsTerminal[index],
    Number: segmentsNumber[index],
    CheckinDate: segmentsCheckinDate[index],
    CheckoutDate: segmentsCheckoutDate[index],
    CheckinTime: segmentsCheckinTime[index],
    CheckoutTime: segmentsCheckoutTime[index],
  }));

  const cancellationDate = validation(FIELDS.CANCELLATION_DATE, CancellationDate);
  const cancellationTime = validation(FIELDS.CANCELLATION_TIME, CancellationTime);

  if (
    name
    || checkinDate
    || checkoutDate
    || checkinTime
    || checkoutTime
    || departureAirport
    || arrivalAirport
    || number
    || terminal
    || providerOrderId
    || cancellationDate
    || cancellationTime
  ) {
    return {
      isValid: false,
      validation: {
        Name: name,
        ProviderOrderId: providerOrderId,
        CancellationDate: cancellationDate,
        CancellationTime: cancellationTime,
        Segments: segments,
      },
    };
  }

  return {
    isValid: true,
    validation: {
      Name: '',
      ProviderOrderId: '',
      CancellationDate: '',
      CancellationTime: '',
      Segments: segments,
    },
  };
};

const selectEmployee = async ({
  getEmployeeDataByID,
  vipHallTripFieldValidation,
}, CompanyId, selectedEmployeesIds, employeesListForPick, Departments, index, Id) => {
  const currentEmployee = await getEmployeeDataByID(Id);

  const updSelectedEmployeesIds = [...selectedEmployeesIds, Id];
  const updEmployeesListForPick = employeesListForPick.map((emp) => (index === emp.id
    ? { ...emp, ...currentEmployee }
    : emp));

  const result = vipHallTripFieldValidation(FIELDS.EMPLOYEELIST, updSelectedEmployeesIds);

  const departments = index === 0 ? findDepartments(currentEmployee, CompanyId) : Departments;

  return {
    departments, result, updSelectedEmployeesIds, updEmployeesListForPick,
  };
};

const decreaseCountEmployees = (employeesListForPick, selectedEmployeesIds) => {
  if (employeesListForPick.length === 1) {
    return {};
  }

  const currentEmployeeId = employeesListForPick.find((el) => el.id === employeesListForPick.length - 1).Id;
  const updSelectedEmployeesIds = selectedEmployeesIds.filter((empId) => empId !== currentEmployeeId);

  return updSelectedEmployeesIds;
};

const selectAnalytics = (
  currentAnalytics, selectedTripAnalytics, selectedServiceAnalytics, analyticsValueId, setTripAnalytics,
) => {
  const { ApplyToTrip } = currentAnalytics;
  const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

  const updatedAnalyticsValues = !analyticsValueId
    ? removeAnalytics(analyticsValues, currentAnalytics)
    : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

  const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

  if (ApplyToTrip) {
    setTripAnalytics(updatedAnalyticsValues);
  }

  const updatedServiceAnalytics = ApplyToTrip
    ? {}
    : { UserAnalytics: updatedAnalyticsValues };

  return { updatedServiceAnalytics, analyticsValidation };
};

export {
  createStateTrip,
  editStateTrip,
  editingStateTrip,
  copyStateTrip,
  normalizeDateConfirm,
  normalizeFlightConfirm,
  selectEmployee,
  removeEmployeeSuggest,
  validateDateFields,
  validateSubmitFields,
  decreaseCountEmployees,
  selectAnalytics,
};
